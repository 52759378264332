import React from "react";
import {Link} from "gatsby";

export default function AppPrivacyPolicy(){
    return (
        <div className="row text-center">
            <div className="col-12">
                <h1>Privacy Policy</h1>
                <p>Приложение не собирает персональны данные / Application doesn't collect personal data</p>
            </div>
        </div>
    )
}
